import React, {useEffect, useState} from "react";
import axios from "axios";
import apiUrl from "../apiUrl";
import apiurl from "../apiUrl";
import FileUploader from "../filemanagement/FileUploader";
import {Link} from "react-router-dom";
function JobAddForm(){
const [showForm,setShowfomr] = useState(false);
const [formAddsend, setFormAddSend] = useState(false);
const [formFilessend, setFormFilesSend] = useState(false);
const [formFields, setFormFields] = useState({anz_vorteile_title:'',anz_aufgaben_title:'',anz_title:'',anz_start:'',anz_routing:'',anz_slogan:'',anz_desc:'',anz_place:'',anz_name_asp:'',anz_mail_asp:'',anz_job_type:'',anz_vorteile:'',anz_aufgaben:'',anz_slogan_title:'',});

    const [area,setArea] = useState("Jobs")
const [showSecondForm, setShowSecondForm] = useState(false);
const [showSucess, setShowShowSucess] = useState(false);
    const [masterForm,setMasterForm] = useState(true);
const [files,setFiles] = useState({area:'jobs',sendfiles:[],id:99});
    const [dataFetched, setDataFetched] = useState(false);
    const [jobid, setJobid] = useState(files.id);
    const fetchurl = apiurl + "getjoblastid.php";
    const  refImageUploader = React.useRef();
    const handleUploader = () =>{

        refImageUploader.current.click();
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(fetchurl);
                const result = await response.json();
                setFiles({ ...files, id: result });


            } catch (error) {
                console.error('Fehler', error);
            }
        };

        if (formAddsend && !dataFetched) {
            getData();
            setDataFetched(true);
        }
    }, [formAddsend, dataFetched]);


    const handleform = async (e) => {
        e.preventDefault();


        try {
            if(!formAddsend) {
                const response = await axios.post(apiurl+'savejobdata.php', formFields, {
                    headers: {
                        'Content-Type': 'application/json', // Setzen Sie den richtigen Content-Type
                    },

                });

                if (response.status === 200) {

                    setFormAddSend(true);
                    setMasterForm(false)
                    setShowSecondForm(true);
                } else {

                    console.error('Fehler bei der Anfrage:', response.status);
                }
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }


    };
    const handlefileUpload = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const fileList = files.sendfiles;
        formData.append('area',area);
        formData.append('jobid',jobid);
        try {

                for (let i = 0; i < fileList.length; i++) {
                    formData.append('sendfiles[]', fileList[i]);

                }

                const response = await axios.post(apiurl + 'fileuploader.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {

                    setJobid(response.data.jobid);


                         } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }

        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }

        setShowSecondForm(false);
        setShowShowSucess(true);
    };


    return(
        <section className="mio-form-fields">




            {masterForm ? (
            <div className="inlay">
            <div className="flexrow">
         <div className="mio-form-field tab-flex-8 flex-12">







                <div className="mio-form-field tab-flex-8 flex-12">
             <label htmlFor={"slogan"}>Job Slogan</label>
             <input
                 id={"slogan"}
                 type="text"
                 value={formFields.anz_slogan}
                 onChange={(e)=>setFormFields({...formFields, anz_slogan: e.target.value})}
                   />
         </div>  <div className="mio-form-field tab-flex-8 flex-12">
             <label htmlFor={"slogantitle"}>Text veränderbarer Jobtitel</label>
             <input
                 id={"slogantitle"}
                 type="text"
                 value={formFields.anz_slogan_title}
                 onChange={(e)=>setFormFields({...formFields, anz_slogan_title: e.target.value})}
                   />
         </div>


             <label htmlFor={"jobtitel"}>Jobtitel</label>
             <input
                 id={"jobtitel"}
                 type="text"
                 value={formFields.anz_title}
                 onChange={(e)=>setFormFields({...formFields, anz_title: e.target.value})}
             />
         </div>

                <div className="mio-form-field tab-flex-8 flex-12">



                    <label htmlFor={"shorturl"}>Short Url</label>
                    <input
                        id={"shorrturl"}
                        type="text"
                        value={formFields.anz_routing}
                        onChange={(e)=>setFormFields({...formFields, anz_routing: e.target.value})}
                    />
                </div>
                <div className="mio-form-field tab-flex-8 flex-12">
                    <label htmlFor={"start"}>Jobstart</label>
                    <input
                        id={"start"}
                        type="text"
                        value={formFields.anz_start}
                        onChange={(e)=>setFormFields({...formFields, anz_start: e.target.value})}
                    />
                </div>

                <div className="mio-form-field tab-flex-8 flex-12">
                    <label htmlFor={"desc"}>Jobbeschreibung</label>
                    <textarea
                        id={"desc"}
                        value={formFields.anz_desc}
                        onChange={(e)=>setFormFields({...formFields, anz_desc: e.target.value})}
                    >

             </textarea>
                </div>
         <div className="mio-form-field tab-flex-4 flex-6">



             <label htmlFor={"todotitle"}>1. Bereich</label>
             <input
                 id={"totdotitle"}
                 type="text"
                 value={formFields.anz_aufgaben_title}
                 onChange={(e)=>setFormFields({...formFields, anz_aufgaben_title: e.target.value})}
             />
             <textarea
                 id={"todos"}
                 value={formFields.anz_aufgaben}
                 onChange={(e)=>setFormFields({...formFields, anz_aufgaben: e.target.value})}
             >

             </textarea>
         </div>
         <div className="mio-form-field tab-flex-4 flex-6">
             <label htmlFor={"vorteiltetitle"}>2. Bereich</label>
             <input
                 id={"vorteiletitel"}
                 type="text"
                 value={formFields.anz_vorteile_title}
                 onChange={(e)=>setFormFields({...formFields, anz_vorteile_title: e.target.value})}
             />
             <textarea
                 id={"for"}
                 value={formFields.anz_vorteile}
                 onChange={(e)=>setFormFields({...formFields, anz_vorteile: e.target.value})}
             >

             </textarea>
         </div>
         <div className="mio-form-field tab-flex-4 flex-6">
             <label htmlFor={"city"}>Ort</label>
             <input
                 id={"city"}
                 type="text"
                 value={formFields.anz_place}
                 onChange={(e)=>setFormFields({...formFields, anz_place: e.target.value})}



                 />
         </div>
         <div className="mio-form-field tab-flex-4 flex-6">
             <label htmlFor={"jobtype"}>Job Typ</label>
             <input
                 id={"jobtype"}
                 type="text"
                 value={formFields.anz_job_type}
                 onChange={(e)=>setFormFields({...formFields, anz_job_type: e.target.value})}

             />
         </div>
        <div className="mio-form-field tab-flex-3 flex-6">
             <label htmlFor={"asp"}>Ansprechpartner</label>
             <input
                 id={"asp"}
                 type="text"
                 value={formFields.anz_name_asp}
                 onChange={(e)=>setFormFields({...formFields, anz_name_asp: e.target.value})}
                 />
         </div>
        <div className="mio-form-field tab-flex-4 flex-6">
             <label htmlFor={"mailaap"}>E-Mailadresse Ansprechpartner</label>
             <input
                 id={"mailaap"}
                 type="text"
                 value={formFields.anz_mail_asp}
                 onChange={(e)=>setFormFields({...formFields, anz_mail_asp: e.target.value})}
                 />
         </div>
        <div className="mio-form-field tab-flex-8 flex-6 ">
             <button                 className={"cta-button"}
                    onClick={handleform}
                 >
                 Weiter zum Bildupload
             </button>

         </div>

            </div>

            </div>
            ) : ("")}

            {showSecondForm?(






                <section className={"secondform"}>
                <h2>Bilder für das Jobangebot {formFields.anz_title} hochladen</h2>
                    <span title="Bilder hinzufügen" className="addimage" onClick={handleUploader}></span>
                <FileUploader files={files} setFiles={setFiles} refuploadLogo={refImageUploader}/>

                    <button
                        className={"cta-button"}
                        onClick={handlefileUpload}
                    >
                        Bilder speichern >
                    </button>
                </section>
            ):("")}

            {
                showSucess
                ?
                    (
                        <section className={"successform"}>
                            <h2>Dateien wurden gespeichert</h2>
                      <Link to="/jobS">Zu den Stellenanzeigen</Link>
                             </section>

                    )
                    :''
            }



        </section>


    )
}
export default JobAddForm;