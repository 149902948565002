import styles from "../evolve-framework/pages/pages.module.css";
import React, { useState } from "react";

function CodeEditor({ formFields, setFormFields,hasImages }) {
    const [buttonSetting, setButtonSettings] = useState({ View: "Visual", isActive: 1 });

    return (
        <div className={`mio-form-field tab-flex-8 flex-12 ${styles.editorframe}`}>

           <p className={styles.viewstate}> {buttonSetting.View}</p>
            <div className={styles.buttonmenu}>
                <button
                    onClick={() => setButtonSettings({ View: "Visual", isActive: 1 })}
                    className={buttonSetting.isActive === 1 ? styles.buttonactive : styles.button}
                >
                    Visuell
                </button>
                <button
                    onClick={() => setButtonSettings({ View: "HTML", isActive: 2 })}
                    className={buttonSetting.isActive === 2 ? styles.buttonactive : styles.button}
                >
                    HTML
                </button>
            </div>
            {buttonSetting.View === "Visual" ? (
                <section
                    onClick={() => setButtonSettings({ View: "HTML", isActive: 2 })}
                    className={styles.htmlpreview}
                    dangerouslySetInnerHTML={{ __html: formFields.page_content }}
                ></section>
            ) : (
                <textarea
                    value={formFields.page_content}
                    className={`${styles.txtHTML} ${ hasImages ? styles.nomargin:'' }`}
                    onChange={(e) => setFormFields({ ...formFields, page_content: e.target.value })}
                ></textarea>)
            }
        </div>
    );
}

export default CodeEditor;
