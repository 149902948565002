import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import JobsListItems from "./JobsListItems";
import apiurl from "../apiUrl";

import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Jobs({ isCollapsed}){

    const fetchurl = apiurl+"getjobdata.php";
    const [jobs, setJobs] = useState([]);

    useEffect(() =>{

        const getData = async () =>{
            try{
                const response = await fetch(fetchurl);
                const result = await response.json();
                setJobs(result);

              }
            catch (error){
                console.error('Fehler', error);
            }
        };
        getData();
    },[jobs]);

    if(jobs.length === 0) {
        return (
         <BackendMainContainer navIsCollapsed={isCollapsed}>
                <div className={"mioui-section-list"}>

                    <h1>Stellenanzeigen</h1>
                    <p>Noch keine Stellenanzeigen geschaltet.</p>
                    <Link to="/jobs/add">Neue Anzeige verfassen</Link>

                </div>

         </BackendMainContainer>
        );

    }
    else {


        return (
            <BackendMainContainer navIsCollapsed={isCollapsed}>



                <div className={"mioui-section-list"}>

                    <h1>Liste Stellenanzeigen</h1>
                    <table>
                        <thead>
                        <tr>
                            <th>Anzeigentitel:</th>

                            <th>Bearbeiten</th>
                            <th>Löschen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            jobs.map((job) => (
                                <JobsListItems key={job.anz_id} job={job}></JobsListItems>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </BackendMainContainer>        )
    }

}
export default Jobs;
