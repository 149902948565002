import { useState} from "react";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Tracking({isCollapsed}){


    return (
       <BackendMainContainer navIsCollapsed={isCollapsed}>

            <div className={"mioui-section-list"}>
                <h1>Auswertungen</h1>
                </div>

       </BackendMainContainer>
            );

}
export default Tracking;
