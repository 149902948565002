import { useState} from "react";
import FormAddForm from "./FormAddForm";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function FormAdd({ isCollapsed}){


    return (
        <BackendMainContainer navIsCollapsed={isCollapsed}>  <div className={"mioui-section-list"}>
                <h1>Formular hinzufügen</h1>
                </div>
<FormAddForm/>
        </BackendMainContainer>
            );

}
export default FormAdd;
