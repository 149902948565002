import React from "react";








function Pages(){

    return(

        <section className="mio-ui-content">
            <h2>Jobseitenübersicht</h2>

        </section>
    )
}
export default Pages;