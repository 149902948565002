
import React from "react";
import styles from "../evolve-framework/pages/backendmaster.module.css"
function BackendMainContainer({children, navIsCollapsed}){



    return(
        <section className={`${styles.backendmasterframe} ${navIsCollapsed && styles.backendcollapsed}`}>

            {children}

        </section>

    )




}
export default BackendMainContainer;