import React from 'react';
import styles from "./fileuploader.module.scss";
function FileUploader({ files, setFiles, refuploadLogo, area }) {
    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles({ sendfiles: selectedFiles});
    };

    const showUpload = () =>{

        refuploadLogo.current.click();
    }
     return (
        <div className="mio-form-field flex-12">
            <form encType="multipart/form-data">

            <input
                className={styles.filuploadinput}
                type="file"
                name="sendfiles"
                onChange={handleFileChange}
                multiple
                ref={ refuploadLogo }
            />
            </form>

            {
                area ==="edit" ?(
                    <span className={styles.info}>Klicken Sie auf das Bild um es zu tauschen.</span>

                ):
                    (
                ""
                    )
            }




        </div>
    );
}

export default FileUploader;
