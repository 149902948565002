import {useEffect, useState} from "react";
import FormEditForm from "./FormEditForm";
import {useParams} from "react-router-dom";
import axios from "axios";
import apiurl from "../apiUrl";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function FormEdit( { isCollapsed}){
const { formid } = useParams();
const [formular, setFormular] = useState([]);
    const fetchurl = apiurl+"getfrmdatabyid.php";
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { formid });
                const result = response.data;


                    setFormular(result[0]);

            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, []);


    return (
       <BackendMainContainer navIsCollapsed={isCollapsed}>
            <div className={"mioui-section-list"}>
                <h1>Formular bearbeiten</h1>
                </div>
            <FormEditForm formular={formular} formularid={formid}  />

</BackendMainContainer>
            );

}
export default FormEdit;
