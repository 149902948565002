import React from "react";
import PageData from "./PageData";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Imprint({isCollapsed}){

    const [hasUpdate, setHasUpdate] = React.useState(true);
function backform(){

    setHasUpdate(true);
}
    return(

        <BackendMainContainer navIsCollapsed={isCollapsed}>
            <h1>Impressum</h1>


            {


                hasUpdate ? (


                        <PageData pageid='2'
                                  setHasUpdate={setHasUpdate}
                                  hasImages={false}
                        />
                    ) :
                    (

                        <section className={"statesave"}>
                        <p>Daten wurde gepeichert.</p>

                            <button className={"cta-button"} onClick={backform}>Zürück</button>
                        </section>

                    )}


        </BackendMainContainer>


    )
}

export default Imprint;