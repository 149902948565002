import { useState} from "react";
import React from "react";
import axios from "axios";
import apiurl from "../apiUrl";
import FileUploader from "../filemanagement/FileUploader";
import ImageAnzloader from "../filemanagement/ImageAnzloader";
import {Link} from "react-router-dom";
function JobEditForm({ job, paramid }){
    const [formsend, setFormSend] = useState(false);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [files,setFiles] = useState({area:'jobs',sendfiles:[],id:99});
    const [area,setArea] = useState("Jobs");
    const [jobid, setJobid] = useState(paramid);
    const [showSucess, setShowShowSucess] = useState(false);
    const [masterForm,setMasterForm] = useState(true);
    const [formFields, setFormFields] = useState({anz_vorteile_title:job.anz_vorteile_title,anz_aufgaben_title:job.anz_aufgaben_title,anz_slogan_title:job.anz_slogan_title,anz_title:job.anz_title,anz_slogan:job.anz_slogan,anz_desc:job.anz_desc,anz_start:job.anz_start,anz_aufgaben:job.anz_aufgaben,anz_routing:job.anz_routing,anz_vorteile:job.anz_vorteile,anz_place:job.anz_place,anz_name_asp:job.anz_name_asp,anz_mail_asp:job.anz_mail_asp,anz_job_type:job.anz_job_type,anz_id: job.anz_id});
    const refImageUploader = React.useRef();


    const handleform = async (e) => {
        e.preventDefault();

        try {
            if(!formsend) {
                const response = await axios.post(apiurl+'updatejobdata.php', formFields, {
                    headers: {
                        'Content-Type': 'application/json', // Setzen Sie den richtigen Content-Type
                    },
                });

                if (response.status === 200) {

                    console.log('Daten erfolgreich gesendet.');
                    setFormSend(true);
                    setMasterForm(false)
                    setShowShowSucess(false);
                    setShowSecondForm(true);
                } else {
                    // Fehler bei der Anfrage
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };
const handleUploader = () =>{

    refImageUploader.current.click();
}
    const handlefileUpload = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const fileList = files.sendfiles;
        formData.append('area',area);
        formData.append('jobid',jobid);
        try {

            for (let i = 0; i < fileList.length; i++) {
                formData.append('sendfiles[]', fileList[i]);

            }

            const response = await axios.post(apiurl + 'fileedituploader.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
console.log(response.data);
                setJobid(response.data.jobid);


            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }

        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }

        setShowSecondForm(false);
        setShowShowSucess(true);
    };




    return(
        <section className="mio-form-fields">

            {masterForm ? (
                <div className="inlay">
                    <div className="flexrow">

                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Job Slogan</label>
                            <input
                                type="text"
                                value={formFields.anz_slogan}
                                onChange={(e)=>setFormFields({...formFields, anz_slogan: e.target.value})}
                            />
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Text für die Fragestellung</label>
                            <input
                                type="text"
                                value={formFields.anz_slogan_title}
                                onChange={(e)=>setFormFields({...formFields, anz_slogan_title: e.target.value})}
                            />
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Jobtitel</label>
                            <input
                                type="text"
                                value={formFields.anz_title}
                                onChange={(e)=>setFormFields({...formFields, anz_title: e.target.value})}
                            />
                        </div>

                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Short-Url</label>
                            <input
                                type="text"
                                value={formFields.anz_routing}
                                onChange={(e)=>setFormFields({...formFields, anz_routing: e.target.value})}
                            />
                        </div>

                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Jobstart</label>
                            <input
                                type="text"
                                value={formFields.anz_start}
                                onChange={(e)=>setFormFields({...formFields, anz_start: e.target.value})}
                            />
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-12">
                            <label>Jobbeschreibung</label>
                            <textarea
                                value={formFields.anz_desc}
                                onChange={(e)=>setFormFields({...formFields, anz_desc: e.target.value})}
                            >

             </textarea>
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-6 ">
                            <label>1.Bereich</label>
                            <input
                                type="text"
                                value={formFields.anz_aufgaben_title}
                                onChange={(e)=>setFormFields({...formFields, anz_aufgaben_title: e.target.value})}
                            />
                            <textarea
                                value={formFields.anz_aufgaben}
                                onChange={(e)=>setFormFields({...formFields, anz_aufgaben: e.target.value})}
                            >

             </textarea>
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-6">
                            <label>2.Bereich</label>
                            <input
                                type="text"
                                value={formFields.anz_vorteile_title}
                                onChange={(e)=>setFormFields({...formFields, anz_vorteile_title: e.target.value})}
                            />
                            <textarea
                                value={formFields.anz_vorteile}
                                onChange={(e)=>setFormFields({...formFields, anz_vorteile: e.target.value})}
                            >

             </textarea>
                        </div>
                        <div className="mio-form-field tab-flex-4 flex-6">
                            <label>Ort</label>
                            <input
                                type="text"
                                value={formFields.anz_place}
                                onChange={(e)=>setFormFields({...formFields, anz_place: e.target.value})}



                            />
                        </div>
                        <div className="mio-form-field tab-flex-4 flex-6">
                            <label>Job Typ</label>
                            <input
                                type="text"
                                value={formFields.anz_job_type}
                                onChange={(e)=>setFormFields({...formFields, anz_job_type: e.target.value})}

                            />
                        </div>
                        <div className="mio-form-field tab-flex-3 flex-6">
                            <label>Ansprechpartner</label>
                            <input
                                type="text"
                                value={formFields.anz_name_asp}
                                onChange={(e)=>setFormFields({...formFields, anz_name_asp: e.target.value})}
                            />
                        </div>
                        <div className="mio-form-field tab-flex-4 flex-6">
                            <label>E-Mailadresse Ansprechpartner</label>
                            <input
                                type="text"
                                value={formFields.anz_mail_asp}
                                onChange={(e)=>setFormFields({...formFields, anz_mail_asp: e.target.value})}
                            />
                        </div>
                        <div className="mio-form-field tab-flex-8 flex-6 mr-3">
                            <button
                                className={"cta-button"}
                                onClick={handleform}
                            >
                                Speichern
                            </button>
                        </div>

                    </div>
                </div>
            ) : ("")}
            {
                showSecondForm
                ?(

                <section className={"secondform"}>
                    <h2>Bilder</h2>
                    <span title="Bilder hinzufügen" className="addimage" onClick={handleUploader}></span>
                    <ImageAnzloader activepageid={paramid}/>


                    <section className="fileuploader">
                    <FileUploader files={files} setFiles={setFiles} refuploadLogo={refImageUploader}/>
                    <button
                        className={"cta-button"}
                        onClick={handlefileUpload}
                    >
                        Bilder speichern
                    </button>
                </section>
                </section>
            ):("")}
            {
                showSucess
                    ?
                    (
                        <section className={"successform"}>
                            <h2>Dateien wurden gespeichert</h2>
                            <Link to="/jobs">Zu den Stellenanzeigen</Link>
                        </section>

                    )
                    :''
            }

        </section>

    )
}
export default JobEditForm;