import { useState} from "react";
import JobAddForm from "./JobAddForm";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function JobsAdd({ isCollapsed}){


    return (
       <BackendMainContainer navIsCollapsed={ isCollapsed}>
            <div className={"mioui-section-list"}>
                <h1>Einen neuen Job hinzufügen</h1>
                </div>
<JobAddForm/>
       </BackendMainContainer>
            );

}
export default JobsAdd;
