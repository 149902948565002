import {useState, useEffecte, useEffect} from "react";
import { Link } from "react-router-dom";
import FormListItems from "./FormListItems";
import apiurl from "../apiUrl";
import JobsListItems from "../jobs/JobsListItems";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Forms({ isCollapsed }){
    const fetchurl = apiurl+"getformdata.php";
    const [form, setForm] = useState([]);
    useEffect(() =>{

        const getData = async () =>{
            try{
                const response = await fetch(fetchurl);
                const result = await response.json();
                setForm(result);
console.log(response);
            }
            catch (error){
console.log(form);
            }
        };
        getData();
    },[form]);

    if(form.length === 0) {
        return (
<BackendMainContainer navIsCollapsed={isCollapsed}>
    <div className={"mioui-section-list"}>

                    <h1>Formulare</h1>
                    <p>Es wurde noch keine Formulare angelegt.</p>
                    <Link to="/forms/add">Neues Formular anlegen</Link>

                </div>

</BackendMainContainer>
        );

    }
    else {


        return (
           <BackendMainContainer navIsCollapsed={ isCollapsed}>
                <div className={"mioui-section-list"}>

                    <h1>Formulare</h1>
                    <table>
                        <thead>
                        <tr>
                            <th>Name:</th>
                            <th>Bearbeiten</th>
                            <th>Löschen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            form.map((forms) => (
                                <FormListItems key={forms.id} forms={forms}></FormListItems>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
           </BackendMainContainer>
        )
    }
}
export default Forms;
