import React, { useState, useEffect } from "react";
import logstyles from "./login.module.css";
import Logo from '../assets/evolve-logo.svg';
import axios from "axios";
import apiurl from "../apiUrl";

function Login({ setIsLoggedIn }) {
    const [status, setStatus] = useState("Bitte melden Sie sich an");
    const [loginState, setLoginState] = useState("");
    const [userData, setUserData] = useState({ userpassword: '', username: '' });
    const [hasErrors, setHasErrors] = useState(false);
    const [loginCounter, setLoginCounter]= useState(0);
    const [statusLoading,setStattusLoading] = useState("pending");

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            setStattusLoading("Loading");

            const response = await axios.post(apiurl + 'backendpassword.php', userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const result = response.data;
                console.log(result);
                setLoginState(result);

                if (result !== "access") {
                    setStattusLoading("error");
                    setStatus("Fehler in der Anmeldung. Passwort und Benutzer stimmem nicht");
                    setHasErrors(true);
                    setLoginCounter(loginCounter + 1);
                    document.cookie = "loggedin=no; samesite=strict";
                } else {
                    document.cookie = "loggedin=yes; samesite=strict";
                    setIsLoggedIn(true);
                }
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    useEffect(() => {
        document.body.className = "loginpage";
        document.title = "Bitte melden Sie sich an";

    }, []);

    return (
        <section className={logstyles.loginarea}>
            <section className={logstyles.loginformwrapper}>
                <section className={logstyles.loginimage}>
                    <img src={Logo} alt="Evolve" />
                </section>

                {
                    loginCounter !== 3

                    ?(



                <section className={logstyles.form}>
                    <form onSubmit={event => {
                        event.preventDefault();

                    }}>
                        <section className={logstyles.field}>
                            <label htmlFor="username">Benutzername:</label>
                            <input
                                type="text"
                                onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                                id="username"
                                required
                            />
                        </section>
                        <section className={logstyles.field}>
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                onChange={(e) => setUserData({ ...userData, userpassword: e.target.value })}
                                id="password"
                                required
                            />
                        </section>
                        <section className={logstyles.submitbutton}>
                            <button onClick={handleLogin}>

                                {
                                    statusLoading === "Loading"
                                    ? "Daten werden überpüft..."
                                    : "Anmelden"


                                }




                                </button>
                        </section>
                        <section className={hasErrors ? logstyles.staterorrs : logstyles.state}>
                            <p>{status}</p>
                        </section>
                    </form>
                </section>
                    )

            :(

                <p>Zu viele fehlgeschlage Fehlversuche. </p>)

            }
            </section>

        </section>
    );
}

export default Login;
