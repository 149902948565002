import React from "react";
import PageData from "./PageData";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Datenschutz({isCollapsed}){
    const [hasUpdate, setHasUpdate] = React.useState(true);
    function backform(){

        setHasUpdate(true);
    }
    return(

<BackendMainContainer navIsCollapsed={isCollapsed}>
            <h1>Datenschutz</h1>


            {


                hasUpdate ? (


                        <PageData pageid='3'
                                  setHasUpdate={setHasUpdate}
                                  hasImages={false}
                        />
                    ) :
                    (

                        <section className={"statesave"}>
                            <p>Daten wurde gepeichert.</p>

                            <button className={"cta-button"} onClick={backform}>Zürück</button>
                        </section>

                    )}
</BackendMainContainer>



    )
}
export default Datenschutz;