import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./imageloader.module.css";
import apiurl from "../apiUrl";
import imageurl from "../imageurl";

function ImageAnzloader({ activepageid }) {
    const [images, setImages] = useState([]);
    const [counterImages, setCounterImages] = useState(0);




    const fetchurl = apiurl + "getjobimages.php";

    const handleImage = async (imageId) => {

        try {
            const response = await axios.post(apiurl + 'deletejobimages.php', { imageId }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                loadImages();

            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };
    const loadImages = async () => {



        try {
            const response = await axios.post(fetchurl, {activepageid});
            const result = response.data;

            setImages(result);

            setCounterImages(result.length);
        } catch (error) {
            console.error("Fehler", error);
        }
    };
    const deleteImage = (imageId) => {
        handleImage(imageId);

    };

        useEffect(() => {
            const getData = async () => {
                try {
                    // Make a POST request using Axios
                    const response = await axios.post(fetchurl, {activepageid});
                    const result = response.data;


                    setImages(result);

                    setCounterImages(result.length);

                } catch (error) {
                    console.error('Fehler', error);
                }
            };

            getData();
        }, []);

    return (
        <div className="pageimages">
            {counterImages === 0 ? (
          <p>Es sind noch keine Bilder vorhanden.</p>
            ) : counterImages === 1 ? (

                <ul className={styles.pagethumbs}>
                    {images.map((img) => (
                        <li key={img.img_id}>
                            <img
                                alt={img.img_path}
                                src={imageurl + "/" + img.img_path}
                                title={img.img_path}
                            />
                            <span
                                data-image-id={img.img_id}
                                className={styles.deleteImage}
                                onClick={() => deleteImage(img.img_id)}
                            >
                  X
                </span>
                        </li>
                    ))}
                </ul>


            ) : (
                counterImages > 1 && (
                    <ul className={styles.pagethumbs}>
                        {images.map((img) => (
                            <li key={img.img_id}>
                                <img
                                    alt={img.img_path}
                                    src={imageurl + "/" + img.img_path}
                                    title={img.img_path}
                                />
                                <span
                                    data-image-id={img.img_id}
                                    className={styles.deleteImage}
                                    onClick={() => deleteImage(img.img_id)}
                                >
                  X
                </span>
                            </li>
                        ))}
                    </ul>
                )
            )}
        </div>
    );
}

export default ImageAnzloader;
