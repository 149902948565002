import { useState} from "react";
import JobsForForm from "./JobsForForm";
import axios from "axios";
import apiurl from "../apiUrl";
import FormsQuestionAnswers from "./FormsQuestionAnswers";
function FormAddForm(){
const [showAskForm, setShowAskform] = useState(false);
const [formData,setFormData] =useState({formname:'',frmanzeigenid:0});
const [formId, setFormId] = useState(0);
const [foremail,setFormEMail] =useState('');

const handleShowForm = async (e) => {
        e.preventDefault();


        try {

                const response = await axios.post(apiurl+'saveformdata.php', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },

                });

                if (response.status === 200) {

                    const lastid = response.data;
                    setFormId(lastid);

                    setShowAskform(!showAskForm) ;
                } else {

                    console.error('Fehler bei der Anfrage:', response.status);
                }

        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }











}

    return(



        <section className="master-form-field">
            {! showAskForm ? (
        <section className="mio-form-fields">

            <div className="inlay">
            <div className="flexrow">
         <div className="mio-form-field tab-flex-8 flex-12">
             <label>Formularname:</label>
             <input
                 type="text"
                 value={formData.formname}
                 onChange={(event)=>setFormData({...formData, formname:event.target.value})}
                   />
         </div>
       <JobsForForm formData={formData} setFormData={setFormData}/>

        <div className="mio-form-field tab-flex-8 flex-6 mr-3 ml-3">
             <button
                 className={"cta-button"}
                 onClick={handleShowForm}
                 >
                 Speichern & Weiter zur Fragenerstellung
             </button>
         </div>

            </div>
            </div>
        </section>
            ) : (
                <section className="mio-form-fields">
                    <FormsQuestionAnswers formId={formId}/>


                </section>
            )}
    </section>

                )
}
export default FormAddForm;