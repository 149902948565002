import { Link } from "react-router-dom";
import React from "react";
import axios from "axios";
import apiurl from "../apiUrl";
function shorText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
}
function FormListItems({ forms }){
        const [isformDeleted, setFormisDeleted] = React.useState(false);
    const deleteItem = async (formid) =>{
    const confirm = window.confirm("Möchten Sie das Formular wirklich löschen?");

    if(confirm) {

        try {

            const response = await axios.post(apiurl + 'deleteform.php', {formid}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Daten erfolgreich gesendet.' + response.data);
                setFormisDeleted(true);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    }
    }

const resetform = ()=>{
        setFormisDeleted(false);
        window.location.href = '/forms';
}


    return(

    <>

            <tr>
            <td>{forms.frm_name }</td>
            <td><Link to={`/forms/edit/${forms.id}`}>Bearbeiten</Link></td>
            <td><span className="deletebutton"
            onClick={()=>deleteItem(forms.id)}

            >Löschen</span> </td>
</tr>


    </>


    )
}
export default FormListItems;