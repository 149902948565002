import React, { useEffect, useState } from "react";
import apiurl from "../apiUrl";
import axios from "axios";
import CodeEditor from "./CodeEditor";
import FileUploader from "../filemanagement/FileUploader";
import Imageloader from "../filemanagement/Imageloader";
import VideoForm from "./VideoForm";
import styles from '../evolve-framework/pages/pages.module.css';

function PageData({ pageid, setHasUpdate, hasImages, refImage, showVideoForm, setShowVideoForm }) {
    const [formFields, setFormFields] = useState({ page_content: '', dbpageid: pageid });
    const fetchurl = apiurl + "getpages.php";
    const [pages, setPages] = useState({});
    const [codeStyle, setCodeStyle] = useState("Visual");
    const [files, setFiles] = useState({ area: 'uns', sendfiles: [], id: pageid });


    const handleForm = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const fileList = files.sendfiles;

        if (fileList.length > 0) {
            try {
                for (let i = 0; i < fileList.length; i++) {
                  formData.append('sendfiles[]', fileList[i]);
                    formData.append('pageid',pageid)
                }
                const response = await axios.post(apiurl + 'fileuploadercontent.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    setFiles({ area: 'uns', sendfiles: [], id: pageid });

                    console.log(response);

                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }

        try {
            const response = await axios.post(apiurl + 'updatepagedata.php', formFields, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setHasUpdate(false);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl);
                const result = response.data;
                const pagedata = result.find((page) => page.page_id === pageid);

                if (pagedata) {
                    setPages(pagedata);
                    setFormFields({ ...formFields, page_content: pagedata.page_content });
                }
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [pageid]);

    return (

            <section className="mio-form-fields">
                <form onSubmit={event => {
                    event.preventDefault();

                }}>
                    <CodeEditor formFields={formFields} setFormFields={setFormFields} hasImages={hasImages}/>
                    {hasImages ? (
                        <section className="page-images">

                            <section className="activeImages">
                                <Imageloader activepageid={pageid} />
                            </section>
                            <FileUploader files={files} setFiles={setFiles} refuploadLogo={refImage} />
                        </section>
                    ) : null}
                    {showVideoForm ?(
                      <VideoForm pageId={pageid} setShowVideoform={setShowVideoForm}/>

                    ) :null}
                    <button className="cta-button"
                            onClick={handleForm}>
                        Speichern
                    </button>
                </form>
            </section>

    );
}

export default PageData;
