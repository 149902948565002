import { Link } from "react-router-dom";
import styles from "./jobs.module.css";
import axios from "axios";
import apiurl from "../apiUrl";
function shorText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
}
function JobsListItems({ job }){

    const handleDeleItem = async (anz_id) => {
        const confirmed = window.confirm('Möchten Sie dieses Element wirklich löschen?');
        if(confirmed) {
            try {
                const response = await axios.post(apiurl + 'deletejobdata.php', {anz_id}, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {


                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }
    };
    return(
        <tr>
            <td className={styles.boldfont}>{job.anz_title }</td>
            <td><Link to={`/jobs/edit/${job.anz_id}`}>Bearbeiten</Link></td>
            <td className="deletebutton" onClick={()=>handleDeleItem(job.anz_id)}>Löschen</td>
        </tr>
    )
}
export default JobsListItems;