import React from "react";
import apiurl from "../apiUrl";
import axios from "axios";
import fmastyle from "./formqalist.module.scss"

function FormAnswerItems({ questionId, setShowAnswer }) {
    const [answerData, setAnswerData] = React.useState({
        answerContent: "",
        addonquestion:"",
         answerBreakAble: false,
        frmquestionId: questionId,
    });
        const [showForm,setShowForm]= React.useState(setShowAnswer);
    const handleAddAnswer = async (e) => {
        e.preventDefault();

        if(e.isTrusted) {
            if (!answerData.answerContent) {
                return;
            }

            try {
                const response = await axios.post(apiurl + "savefrmanswer.php", answerData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {

                    setAnswerData({answerContent: "", answerBreakAble: false, frmquestionId: questionId});
                    setShowForm(false);
                } else {
                    // Fehler bei der Anfrage
                    console.error("Fehler bei der Anfrage:", response.status);
                }
            } catch (error) {
                console.error("Fehler beim Senden der Daten:", error);
            }
        }
    };

    return (
        <div className="mio-form-field anserwinner tab-flex-8 flex-12">

            {
                showForm ?(

            <div className={fmastyle.addanserform}>

            <label>Antwort</label>
            <input
                type="text"
                value={answerData.answerContent}
                onChange={(e) => setAnswerData({ ...answerData, answerContent: e.target.value })}
            />
            <input
                placeholder={"Zusatzfrage"}
                type="text"
                value={answerData.addonquestion}
                onChange={(e) => setAnswerData({ ...answerData, addonquestion: e.target.value })}
            />
            <input
                type="checkbox"
                checked={answerData.answerBreakAble}
                onChange={(e) => setAnswerData({ ...answerData, answerBreakAble: e.target.checked })}
            />
            Ist Abbruchantwort?

            <button className="cta-button" onClick={handleAddAnswer}>
                Antwort speichern
            </button>
            </div>
                ):("")}
        </div>
    );
}

export default FormAnswerItems;
