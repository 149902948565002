import React from "react";
import apiurl from "../apiUrl";
import axios from "axios";
import FormAnswerItems from "./FormAnwerItems";
import FormQAListEdit from "./FormQAListEdit";
import FormQuestionItems from "./FormQuestionItems";


function FormsQuestionAnswers({ formId }) {
    const [questions, setQuestions] = React.useState([]);
    const [countQuestion, setCountQuestion] = React.useState(0);
    const [Answers, setAnswers] = React.useState([]);
    const [addQuestion, setAddQuestion] = React.useState(false)
    const [showAnswerForm, setshowAnswerForm] = React.useState(false);

    const fetchurl = apiurl + "getfrmquestion.php";
    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { formId });
                const result = response.data;
                setQuestions(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, []); // Keine Abhängigkeiten, wird nur beim ersten Rendern ausgelöst

    React.useEffect(() => {
        console.log(questions); // Hier wird der Wert von questions nach dem Laden der Daten angezeigt
    }, [questions]); // Überwacht Änderungen an questions


    async function loadData(){

        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { formId });
                const result = response.data;
                setQuestions(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }



    const toogleAddQuestForm = () =>{

        if(addQuestion)
        {
            setAddQuestion(false);
        }
        else{
            setAddQuestion(true);
        }

    }



    return (
        <section className="fields-for-frmqa">
            <h2>Fragen & Antworten zum Formular</h2>
            <button
                className="cta-button"
                onClick={toogleAddQuestForm}
                >
                Neue Frage erstellen

            </button>
            <section className={
            addQuestion
        ?  "addquestionform addformvisible"
        : "addquestionform"
        }
>
    <FormQuestionItems formid={formId} setShowForm={setAddQuestion} setQuestions={setQuestions} />
</section>


            {questions.length !== 0 ? (

            <section className="questionlist">

              <FormQAListEdit formID={formId} dataQuestion={questions} setQuestions={setQuestions}/>
            </section>

            ) : (
                <section className="addquestioncontent">

                    {
                        showAnswerForm?(
                    <FormAnswerItems questionId={questions.fmq_id} setShowAnswer={setshowAnswerForm}></FormAnswerItems>
                        )
                    :("")}

                </section>


                )}


        </section>
    );
}

export default FormsQuestionAnswers;
