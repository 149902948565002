import React from "react";

import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function HomeBackend({isCollapsed}){
React.useState( () =>{

    document.body.className="homebackend";
})


    return(


           <BackendMainContainer navIsCollapsed={isCollapsed}>

                <section className="hometeaser hometeaser-first">
                    <h1>Verwaltungsbereich</h1>
                    <p>Willkommen im evolve Verwaltungsbereich. Hier können Sie Ihre Stellenanzeigen sowie die Informationen zum Unternehmen verwalten. Im Statistik-Bereich erhalten Sie Auswertungen zur Performance Ihrer Stellenanzeigen.</p>

                </section>
                <section className="hometeaser hometeaser-second">
                    <h3>Sie haben Fragen oder benötigen Hilfe?</h3>

                    <p>Wir stehen Ihnen gerne zur Seite!</p>
                    <p>
                        Melden Sie sich gerne per Telefon unter 05191 6060-162 oder Email unter <br/>
                        <a href="mailto:info@evolvejobs.de">info@evolvejobs.de</a>
                    </p>
                </section>

           </BackendMainContainer>


    )
}

export default HomeBackend;