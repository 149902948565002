import React from "react";
import fieldstyle from "../evolve-framework/evolve-forms.module.css";
function TextInput({tabSize, deskSize, label, formData, setFormData, inputvalue, fieldname, ...delegate}){

    return(

        <div className={`${fieldstyle.textinput} ${tabSize} ${deskSize}`}>
            <label>{label}</label>
            <input
                type="text"
                value={inputvalue}
                onChange={(e)=>setFormData({...formData, [fieldname] : e.target.value})}
                {...delegate}

            />
        </div>
    )
}
export default TextInput;