import React, { useEffect, useState } from "react";
import apiurl from "../apiUrl";
import axios from "axios";
import styles from "../evolve-framework/settings/settings.module.css";
function Formbuilder({ area }) {
    const pcurl = apiurl + "getpagecontentbyarea.php";
    const savedataurl = apiurl + "savecontentparts.php";
    const [contentData, setContentData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [dataState,setDataState]= React.useState("pending");
    const [buttonText, setButtonText] = React.useState("Speichern");

    useEffect(() => {
        const pcData = async () => {
            try {
                const response = await axios.post(pcurl, { area });
                const result = response.data;
                setContentData(result);
                // Initialize formData with data from the response
                setFormData(result.map((cd) => ({ id: cd.id, headline: cd.headline, text: cd.text })));
            } catch (error) {
                console.error("Fehler", error);
            }
        };

        pcData();
    }, [area]);

    const generateAreaName = (conArea) => {
        switch (conArea) {
            case "form-break":
                return "Text Abbruch";
            case "form-headline":
                return "Headline Bewerberformular";
            case "form-success":
                return "Text Abschluss Formular";
            case "job-special":
                return "Stellenanzeige Inhaltsbox";

            case "job-duration":
                return "Stellenanzeige Dauer für das Formular";
            case "job-list":
                return "Headline für die Stellenangebote";
            default:
                return "No Output";
        }
    };

    const handleInputChange = (id, field, value) => {
        const updatedFormData = formData.map((data) =>
            data.id === id ? { ...data, [field]: value } : data
        );
        setFormData(updatedFormData);
        setDataState("pending");
        setButtonText("Speichern");
    };

    const handleSaveClick = () => {

        setDataState("sending");
        setButtonText("Daten werden gesendet...")
        axios
            .post(savedataurl, formData)
            .then((response) => {

                setDataState("saved")
                setButtonText("Daten wurde erfolgreich gespeichert")

            })
            .catch((error) => {
                console.error("Fehler beim Speichern der Daten.", error);

            setDataState("error")
                setButtonText("Fehler beim Speichern der Daten")

            });
    };


    return (
        <section className={styles.formarea}>
            <h2>Textbausteine für {area==="Formular" ?"die Formulare":"die Stellenangebote"}</h2>
            {contentData.map((cd) => (
                <div className={styles.formitem} key={cd.id}>
                    <h4>{generateAreaName(cd.con_area)}</h4>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                        }}
                    >
                        {cd.headline && (
                            <input
                                id={`headline_${cd.id}`}
                                value={formData.find((data) => data.id === cd.id)?.headline}
                                onChange={(e) => handleInputChange(cd.id, "headline", e.target.value)}
                            />
                        )}

                        {cd.text && (
                            <textarea
                                id={`text_${cd.id}`}
                                value={formData.find((data) => data.id === cd.id)?.text}
                                onChange={(e) => handleInputChange(cd.id, "text", e.target.value)}
                            />
                        )}

                        <button
                            className={

                                styles.button

                            }



                            onClick={handleSaveClick}>{buttonText}</button>
                    </form>
                </div>
            ))}
        </section>
    );
}

export default Formbuilder;
