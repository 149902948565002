import {useEffect, useState} from "react";
import apiurl from "../apiUrl";
import CustomerEditForm from "./CustomerEditForms";
import customerAddForm from "./CustomerAddForm";
import CustomerAddform from "./CustomerAddForm";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
import style from "../evolve-framework/evolve-forms.module.css";

function Customers({isCollapsed}){


    const fetchurl = apiurl+"getcustomerdata.php";
    const [frmdata,setfrmData] = useState([]);
    const [custs, setCusts] = useState(frmdata);


 useEffect(() =>{

     const getData = async () =>{
         try{
             const response = await fetch(fetchurl);
             const result = await response.json();
             setfrmData(result);
             setCusts(result);

         }
         catch (error){
             console.error('Fehler', error);
         }
     };
     getData();
 },[]);


    if(frmdata.length === 0) {

        return <section className={'mio-ui-content'}><div className={"mioui-section-form"}><h1>Kundendaten hinzufügen</h1><CustomerAddform/></div></section>
            }

else {

        return (
     <BackendMainContainer navIsCollapsed={isCollapsed}>
            <div className={style.formsection}>
                <h1>Kundendaten</h1>


                    {
                        frmdata.map((cust) => (
                            <CustomerEditForm key={cust.id} cust={cust}></CustomerEditForm>
                        ))
                    }
               </div>
     </BackendMainContainer>
        );

    }
}
export default Customers;
