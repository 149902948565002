import React from "react";
import PageData from "./PageData";
import pagecss from "../evolve-framework/pages/pages.module.css";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function Home({navisCollapsed}){
    const [hasUpdate, setHasUpdate] = React.useState(true);
    const [showVideoForm, setShowVideoForm] = React.useState(false);

    const refImageUploader = React.useRef();

    const handleFilUploader = () =>
    {
        refImageUploader.current.click();
    }
    const handleVideo = () =>
    {

    }




    function backform(){

        setHasUpdate(true);
    }
    return(

        <BackendMainContainer navIsCollapsed={navisCollapsed}>
            <h1>Über uns</h1>


            {


                hasUpdate ? (
                        <>
                            <section className={pagecss.mediabuttons}>

                                <ul>
                                    <li><span className={pagecss.addimage} title="Bilder hinzufügen" onClick={handleFilUploader}></span></li>
                                    <li><span className={pagecss.addvideo} title="Videopfad hinzufügen" onClick={handleVideo}></span></li>


                                </ul>

                            </section>

                            <PageData pageid='1'
                                      setHasUpdate={setHasUpdate}
                                      hasImages={true}
                                      refImage={refImageUploader}
                            />
                        </>
                    ) :
                    (

                        <>
                            <p>Daten wurde gepeichert.</p>

                            <button className={"cta-button"} onClick={backform}>Zürück</button>
                        </>

                    )}


</BackendMainContainer>

    )
}
export default Home;