import './evolve-framework/evolve-ui-framework.scss';
import React from "react";
import HomeBackend from "./pages/HomeBackend";
import Customers from "./customers/Customers";
import Jobs from "./jobs/Jobs.jsx";
import JobsAdd from "./jobs/JobsAdd.jsx";
import JobsEdit from "./jobs/JobsEdit.jsx";
import Forms from "./forms/Forms.jsx";
import FormAdd from "./forms/FormAdd.jsx";
import FormEdit from "./forms/FormEdit";
import Tracking from "./tracking/Tracking.jsx";
import Pages from "./pages/Pages";
import Uns from "./pages/Uns";
import Imprint from "./pages/Imprint";
import Datenschutz from "./pages/Datenschutz";
import Home from "./pages/Home";
import Login from "./Login/Login";
import {BrowserRouter as Router, Routes, Route, Link, BrowserRouter, Form} from "react-router-dom";
import Header from "./layout/Header";
import Settings from "./settings/Settings";
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function App() {
    const [navIsCollapsed, setNaviIsCollapsed] = React.useState(false);
  const [isLogged, setIsLogged]= React.useState(false);
    let cookie = getCookie("loggedin");

  React.useEffect(()=>

  {
     document.body.className = "";
     document.title = "evolve Backend";
  });

  return (
      <section className="mio-office-master-section">
        {
          isLogged || cookie==="yes" ?(
        <BrowserRouter>
          <Header setIsLogged={setIsLogged} navIsCollapsed={navIsCollapsed} setNaviIsCollapsed={setNaviIsCollapsed}> </Header>

          <Routes>
            <Route path="/" element={<HomeBackend isCollapsed={navIsCollapsed}/>}/>
            <Route path="/customers" element={<Customers isCollapsed={navIsCollapsed}/>}/>
            <Route path="/jobs" element={<Jobs isCollapsed={navIsCollapsed}/>}/>
            <Route path="/jobs/add" element={<JobsAdd isCollapsed={navIsCollapsed}/>}/>
            <Route path="/forms" element={<Forms isCollapsed={navIsCollapsed}/>}/>
            <Route path="/forms/add" element={<FormAdd isCollapsed={navIsCollapsed}/>}/>
            <Route path="/forms/edit/:formid" element={<FormEdit isCollapsed={navIsCollapsed}/>}/>

            <Route path="/tracking" element={<Tracking isCollapsed={navIsCollapsed}/>}/>
            <Route path="/jobs/edit/:jobid" element={<JobsEdit isCollapsed={navIsCollapsed}/>}/>
            <Route path="/pages" element={<Pages isCollapsed={navIsCollapsed}/>}/>
            <Route path="/pages/uns" element={<Uns navisCollapsed={navIsCollapsed}/>} />
            <Route path="/pages/impressum" element={<Imprint isCollapsed={navIsCollapsed}/>}/>
            <Route path="/pages/datenschutz" element={<Datenschutz isCollapsed={navIsCollapsed}/>}/>
            <Route path="/pages/startseite" element={<Home navisCollapsed={navIsCollapsed}/>}/>
            <Route path="/settings" element={<Settings isCollapsed={navIsCollapsed}/>}/>


          </Routes>




        </BrowserRouter>
          )

        : (<Login setIsLoggedIn={setIsLogged}/>)

        }





      </section>
  );
}

export default App;
