import { useState, useEffect} from "react";
import JobsForForm from "./JobsForForm";
import axios from "axios";
import apiurl from "../apiUrl";
import FormsQuestionAnswers from "./FormsQuestionAnswers";
import JobsForEditForm from "./JobsForEditForm";
function FormEditForm({ formular, formularid }) {
    const [showAskForm, setShowAskform] = useState(false);

    const [dataState, setDataState] = useState("pending");
    const [formData, setFormData] = useState({
        frm_name: formular.frm_name,
        frmanzeigenid: formular.anz_id,

    });

    useEffect(() => {
    setDataState("pending");
        setFormData({
            frm_name: formular.frm_name,
            frmanzeigenid: formular.anz_id,
            formid: formularid
        });
    }, [formular]);
const handleUpdateForm = async (e) => {
    e.preventDefault();


    try {

        setDataState("save")

        const response = await axios.post(apiurl + 'updateformdata.php', formData, {
            headers: {
                'Content-Type': 'application/json',
            },

        });

        if (response.status === 200) {
        setDataState("saved");

            console.log(response.data);
          } else {

            console.error('Fehler bei der Anfrage:', response.status);
        }

    } catch (error) {
        console.error('Fehler beim Senden der Daten:', error);
    }
}

    return(



        <section className="master-form-field">

        <section className="mio-form-fields">

            <div className="inlay">
            <div className="flexrow">
         <div className="mio-form-field tab-flex-8 flex-12">
             <label>Formularname: </label>
             <input
                 type="text"
                 value={formData.frm_name}
                 onChange={(event)=>setFormData({...formData, frm_name:event.target.value})}
                   />
         </div>

<JobsForEditForm frmJobid={formular.anz_id}  formData={formData} setFormData={setFormData}/>



            </div>
            </div>

<FormsQuestionAnswers formId={formularid}/>

            <div className="mio-form-field tab-flex-8 flex-12 ">
                <button
                    className={"cta-button"}
                    onClick={handleUpdateForm}
                >
                    {
                        dataState==="saved" ?(
                            "Gespeichert"
                        ) :(

                    "Speichern"
                        )}
                        </button>
            </div>
    </section>
        </section>

                )
}
export default FormEditForm;