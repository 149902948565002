import React, { useEffect, useState } from "react";
import apiurl from "../apiUrl";

function JobsForEditForm({ formData, setFormData, frmJobid }) {
    const fetchurl = apiurl + "getjobdata.php";
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(fetchurl);
                const result = await response.json();
                setJobs(result);
            } catch (error) {
            }
        };
        getData();
    }, []);

    return (
        <div className="mio-form-field tab-flex-8 flex-12">
            <label>Anzeigenauswahl</label>
            <select
                name={"anzeige"}
                onChange={(event) => setFormData({ ...formData, frmanzeigenid: event.target.value })}
            >
                <option>Bitte auswählen</option>
                {jobs.map((job) => (
                    <option
                        key={job.anz_id}
                        value={job.anz_id}
                        selected={job.anz_id === frmJobid ? "selected" : undefined}
                    >
                        {job.anz_title}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default JobsForEditForm;
