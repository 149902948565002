import React, { useState} from "react";
import axios from 'axios';
import apiurl from "../apiUrl";
import FileUploader from "../filemanagement/FileUploader";
function CustomerAddform(){
    const [formData, setFormData] = useState({ cmp_name: '', cmp_str:'',cmp_zip:'',cmp_city:'',cmp_country:'',cmp_email: ''
                                                       ,cmp_tel:'',cmp_fax:''});
    const [formsend, setFormSend] = useState(false);
    const [showFileUpload, setFileUpload] = useState(false);
    const [files,setFiles] = useState({area:'logo',sendfiles:[],id:1});
    const [area,setArea] = useState("Logo");
    const [logoid, setlogoid] =useState(1);
    const [showLogoUpload, setLogoUpload] = useState(false);
    const refuploadLogo = React.useRef();

    const tooglefileupload = () =>{


        refuploadLogo.current.click();
    }
    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles({ ...files, sendfiles: selectedFiles });
    };

    const handlefileUpload = async () => {
        try {
            const formDataUpload = new FormData();
            const fileList = files.sendfiles;
            formDataUpload.append('area', area);
            formDataUpload.append('logoid', logoid);

            for (let i = 0; i < fileList.length; i++) {
                formDataUpload.append('sendfiles[]', fileList[i]);
            }

            const response = await axios.post(apiurl + 'fileuploaderlogo.php', formDataUpload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setFileUpload(false);
                setFiles([]);
                setFormSend(true);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!formsend) {
                // Hier werden die Formulardaten an den Server gesendet
                const response = await axios.post(apiurl + 'savecustomerdata.php', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    console.log('Daten erfolgreich gesendet.');
                    setFormSend(true);
                    handlefileUpload(); // handlefileUpload wird erst nach dem erfolgreichen Absenden der Formulardaten aufgerufen
                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    const tooglefileuip = () => {
        refuploadLogo.current.click();
    };



    return(

        <form>
        <section className="mio-form-fields">
            {
                !formsend ? (
            <div className="inlay">
            <div className="flexrow">



         <div className="mio-form-field tab-flex-8 flex-12">
             <label>Firmenname</label>
             <input
                 type="text"
                 value={formData.cmp_name}
                 onChange={(e)=>setFormData({...formData, cmp_name: e.target.value})}
                 />
         </div>
         <div className="mio-form-field tab-flex-8 flex-12">
             <label>Straße</label>
             <input
                 type="text"
                 value={formData.cmp_str}
                 onChange={(e)=>setFormData({...formData, cmp_str: e.target.value})}

             />
         </div>
         <div className="mio-form-field tab-flex-3 flex-4">
             <label>PLZ</label>
             <input
                 type="text"
                 value={formData.cmp_zip}
                 onChange={(e)=>setFormData({...formData, cmp_zip: e.target.value})}

             />
         </div>
         <div className="mio-form-field tab-flex-3 flex-4">
             <label>Ort</label>
             <input
                 type="text"
                 value={formData.cmp_city}
                 onChange={(e)=>setFormData({...formData, cmp_city: e.target.value})}

                 />
         </div>
        <div className="mio-form-field tab-flex-3 flex-4">
             <label>Land</label>
             <input
                 type="text"
                 value={formData.cmp_country}
                 onChange={(e)=>setFormData({...formData, cmp_country: e.target.value})}

                 />
         </div>
        <div className="mio-form-field tab-flex-4 flex-4">
             <label>Telefon</label>
             <input
                 type="text"
                 value={formData.cmp_tel}
                 onChange={(e)=>setFormData({...formData, cmp_tel: e.target.value})}

                 />
         </div>
        <div className="mio-form-field tab-flex-4 flex-4">
             <label>Telefax</label>
             <input
                 type="text"
                 value={formData.cmp_fax}
                 onChange={(e)=>setFormData({...formData, cmp_fax: e.target.value})}

                 />
         </div><div className="mio-form-field tab-flex-4 flex-4">
             <label>E-Mailadresse</label>
             <input
                 type="text"
                 value={formData.cmp_email}
                 onChange={(e)=>setFormData({...formData, cmp_email: e.target.value})}

                 />
         </div>
         </div>
                <div className="mio-form-field tab-flex-8 flex-6">

                    <div className="mio-form-field tab-flex-4 flex-6">
                        <label>Logo hochladen</label>
                        <section className="blankimage" onClick={tooglefileupload}>

                        </section>



                        <FileUploader files={files} setFiles={setFiles} area="Lognew" refuploadLogo={refuploadLogo}/>



                    </div>



                    <button
                 className={"cta-button"}
                 onClick={handleSubmit}
                 >
                 Speichern
             </button>

            </div>



            </div>

                ) : (
                    <div className="inlay">

                    <h2>Daten gespeichert</h2>
                    </div>
                )}
        </section>
        </form>

    )
}
export default CustomerAddform;