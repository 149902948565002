import React from "react";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";

import TabNavigation from "./TabNavigation";
import Formbuilder from "./FormBuilder";
import SimpleForm from "./SingleForm";
import styles from "../evolve-framework/settings/settings.module.css";
function Settings ({isCollapsed}){

const [activeArea, setActiveArea] = React.useState("Formulare")
    return (

        <BackendMainContainer navIsCollapsed={isCollapsed}>

            <h1>Einstellungen</h1>
            <section className={styles.tabnavframe}>

<TabNavigation activeArea ={activeArea} setActiveArea = {setActiveArea}/>
            </section>
            <section className={styles.formularframe}>


                {
                    activeArea === "Formulare"
                    ?(
                  <Formbuilder area="Formular"/>
                        )
                   :(
                            <Formbuilder area="Jobs"/>
                    )
                }


            </section>

        </BackendMainContainer>
    )

}
export default Settings;