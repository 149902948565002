import React, { useEffect, useState } from "react";
import axios from "axios";
import apiurl from "../apiUrl";
import fqstyles from "./formqalist.module.css";

function FormAnswerEditItems({ questionid }) {
    const [answer, setAnswer] = useState([]);
    const [showInput, setShowInput] = useState([]);
    const [answercontent, setAnswerContent] = useState([]);
    const [addonquesitioncontent, setAddonQuestionContent] = useState([]);
    const [isBreakable, setIsBreakable] = useState([]);
    const [editAnswerContent, setEditAnswerContent] = useState([]);
    const [elementPosition, setElementPosition] = useState({ id: 0, direction: "up" });

    useEffect(() => {
        loadAnswers();
    }, [questionid]);

    const deleteItem = async (index, ansid) => {
        const confirmDelete = window.confirm("Möchten Sie die Antwort löschen?");
        if (confirmDelete) {
            try {
                const response = await axios.post(apiurl + 'deletefrmanswer.php', { ansid }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    console.log('Daten erfolgreich gesendet.' + response.data);
                    loadAnswers();
                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }
    }

    const upPosition = async (index, ansid) => {
        const updatedElementPosition = { id: ansid, direction: "up" };
        setElementPosition(updatedElementPosition);

        try {
            const response = await axios.post(apiurl + 'updatefrmanswers.php', updatedElementPosition, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Daten erfolgreich gesendet.' + response.data);
                loadAnswers();
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    }

    const updateContent = async (index, id) => {
        const updatedData = {
            content: answercontent[index],
            answerid: id,
            breakable: answer[index].fma_isbreakanswer === "1" ? "1" : "0",
            addquest: addonquesitioncontent[index],
        };
        console.log(updatedData.breakable);
        try {
            const response = await axios.post(apiurl + 'updatefrmanswer.php', updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Daten erfolgreich gesendet.' + response.data);


                // Andere Aktualisierungen
                const updatedShowAnswers = [...showInput];
                updatedShowAnswers[index] = !updatedShowAnswers[index];
                setShowInput(updatedShowAnswers);
                loadAnswers();
                console.log(updatedData.breakable);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    const editItem = (index, id) => {
        const updatedShowAnswers = [...showInput];
        updatedShowAnswers[index] = !updatedShowAnswers[index];
        setShowInput(updatedShowAnswers);
        setAnswerContent("");
    }

    const fetchurl = apiurl + "getfrmanswers.php";

    const loadAnswers = async () => {
        try {
            const response = await axios.post(fetchurl, { questionid });
            const result = response.data;
            setAnswer(result);
            setShowInput(result.map(() => false));
            setAnswerContent(result.map(() => ""));
            setAddonQuestionContent(result.map(() => ""));
            setIsBreakable(result.map(() => false));
            setEditAnswerContent(result.map(() => ""));
        } catch (error) {
            console.error("Fehler", error);
        }
    };

    return (
        <ul className={fqstyles.listfaitems}>
            {answer.map((aw, index) => (
                <li key={aw.fma_id}>
          <span
              className={
                  !showInput[index]
                      ? fqstyles.answeritems + " " + fqstyles.aivisible
                      : fqstyles.answeritems
              }
          >
            {aw.fma_content}
          </span>
                    <section
                        className={
                            showInput[index]
                                ? fqstyles.answerinput + " " + fqstyles.aivisible
                                : fqstyles.answerinput
                        }
                    >
                        <input
                            type="text"
                            value={answercontent[index] || aw.fma_content}
                            onChange={(e) => {
                                const updatedAnswerContent = [...answercontent];
                                updatedAnswerContent[index] = e.target.value;
                                setAnswerContent(updatedAnswerContent);
                            }}
                        />
                        <input
                            type="text"
                            placeholder="Addon Frage"
                            value={addonquesitioncontent[index] || aw.fma_addonquestion}
                            onChange={(e) => {
                                const updatedAddonQuestionContent = [...addonquesitioncontent];
                                updatedAddonQuestionContent[index] = e.target.value;
                                setAddonQuestionContent(updatedAddonQuestionContent);
                            }}
                        />
                        <section className={fqstyles.checkboxarea}>
                        <input
                            type="checkbox"
                            className={fqstyles.statebreakablecheck}
                            checked={aw.fma_isbreakanswer === "1"}
                            onChange={(e) => {
                                const updatedAnswer = { ...aw };
                                updatedAnswer.fma_isbreakanswer = e.target.checked ? "1" : "0"; // Hier wird der String "0" für false verwendet

                                // Ersetzen Sie die Antwort in Ihrer Datenliste
                                const updatedAnswerList = [...answer];
                                updatedAnswerList[index] = updatedAnswer;
                                setAnswer(updatedAnswerList);

                                // Aktualisieren Sie isBreakable basierend auf dem Wert des Kontrollkästchens
                                setIsBreakable(updatedAnswerList[index].breakable);
                            }}
                        />
                        <span className={fqstyles.smallinfo}>Ist Abbruchantwort?</span>
                        <span onClick={() => updateContent(index, aw.fma_id)} className={fqstyles.listupdatebutton}>OK</span>
                        </section>
                        </section>
                    <div className={fqstyles.ansbuttonlist}>
                        <span onClick={() => deleteItem(index, aw.fma_id)} title="Antwort löschen" className={fqstyles.deletebutton}>Löschen</span>
                        <span onClick={() => editItem(index, aw.fma_id)} title="Antwort bearbeiten" className={fqstyles.editbutton}>Bearbeiten</span>
                        <span onClick={() => upPosition(index, aw.fma_id)} title="Nach oben" className={
                            aw.fma_position > 1
                                ? fqstyles.posup
                                : fqstyles.noposup
                        }>Nach Oben</span>
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default FormAnswerEditItems;
