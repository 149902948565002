import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import data from './jobs.json';
import JobEditForm from "../jobs/JobEditForm";
import apiurl from "../apiUrl";
import axios from "axios";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
function JobsEdit({isCollapsed}){
const { jobid } = useParams();
    const fetchurl = apiurl+"getjobdatabyid.php";
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                // Make a POST request using Axios
                const response = await axios.post(fetchurl, { jobid });

                // Access the response data directly, no need for response.json()
                const result = response.data;

                // Update the state with the fetched data
                setJobs(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, []);

    return (
       <BackendMainContainer navIsCollapsed={isCollapsed}>
            <div className={"mioui-section-list"}>
                <h1>Job bearbeiten</h1>

                {
                    jobs.map((job) => (
                        <JobEditForm paramid={jobid} key={jobid} job={job}></JobEditForm>
                    ))
                }




                </div>

       </BackendMainContainer>
            );

}
export default JobsEdit;
