import React from "react";
import styles from "../evolve-framework/settings/settings.module.css";

function TabNavigation({activeArea, setActiveArea}){

const handleArea = (area)=>{

    setActiveArea(area);


}
    return(




        <ul className={styles.tabnavlist}>

            <li onClick={()=>handleArea("Formulare")} className={`${styles.tabnavlistitem} ${activeArea=="Formulare" ? styles.active : ''}`}>Formulare</li>
            <li onClick={()=>handleArea("Jobs")} className={`${styles.tabnavlistitem} ${activeArea=="Jobs" ? styles.active : ''}`}>Stellenanzeigen</li>


        </ul>

    )
}
export default TabNavigation